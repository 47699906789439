<template>
  <div>
    <div class="tab-title-box">
      <span class="tab-item">课程评价</span>
    </div>
    <div class="tab-content-box">
      <div class="class-content">
        <div class="class-ctx clearfix">
          <None v-if="!classList || !classList.length" />
          <div
            v-else
            class="class-item"
            v-for="(item, index) in classList"
            :key="index"
          >
            <img
              :src="item.cover"
              alt=""
              @click="$router.push(`/courseDetail?id=${item.courseId}`)"
            />
            <p>{{ item.title }} - {{ item.teacherName }}</p>
            <span class="outbtn" @click="commentDialogClick(item)">
              <svg
                t="1681093277318"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="12001"
                width="200"
                height="200"
              >
                <path
                  d="M830.620921 704.186168c35.182174-58.159791 53.750628-124.389396 53.750628-192.185656 0-205.323038-167.049535-372.371549-372.371549-372.371549S139.628451 306.677474 139.628451 512.000512 306.676962 884.372061 512 884.372061c69.023986 0 136.185392-19.091697 194.936008-55.296805l146.503817 48.68306c7.431887 2.477978 15.613312 0.523243 21.136541-4.999987 5.545757-5.545757 7.477965-13.727181 5.022514-21.159068L830.620921 704.186168zM663.75365 540.818786 526.613929 677.913454c-4.023131 4.045658-9.319042 6.067975-14.613929 6.067975s-10.590798-2.022317-14.612905-6.067975L360.247374 540.818786c-45.8426-45.8426-45.8426-120.502451 0-166.367579 22.227057-22.182003 51.774389-34.432636 83.205805-34.432636 24.933378 0 48.705587 7.70426 68.546822 22.000762 19.841235-14.296502 43.615492-22.000762 68.546822-22.000762 31.432439 0 60.978748 12.250634 83.206829 34.432636C709.596251 420.317359 709.596251 494.97721 663.75365 540.818786z"
                  fill="#272636"
                  p-id="12002"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="dialogtitle + ' —— 课程评价'"
      size="mini"
      :visible.sync="commentDialogVisible"
      width="30%"
      center
      @close="closeDialogClick"
    >
      <el-form label-width="80px" :model="CommentForm">
        <el-form-item label="课程评分">
          <el-rate
            :disabled="commentDisabled"
            style="margin-top: 10px"
            size="mini"
            v-model="CommentForm.number"
            :colors="colors"
          >
          </el-rate>
        </el-form-item>
        <el-form-item label="课程评价">
          <el-input
            :disabled="commentDisabled"
            type="textarea"
            v-model="CommentForm.content"
            placeholder="请输入文字评价..."
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialogClick">取 消</el-button>
        <el-button size="mini" type="primary" @click="commentClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import edu from "@/api/edu.js";
import { mapActions, mapState } from "vuex";
import None from "@/components/common/no-databox.vue";
export default {
  data() {
    return {
      dialogtitle: "",
      commentDisabled: false,
      CommentForm: { electiveId: null, content: "", number: 0 },
      commentDialogVisible: false,
      isLogin: true,
      courseActive: 1,
      classList: [],
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
    };
  },
  components: {
    None,
  },
  computed: {
    ...mapState({ userInfo: (state) => state.user }),
  },
  mounted() {
    this.init();
  },
  methods: {
    closeDialogClick() {
      this.CommentForm = { electiveId: null, content: "", number: 0 };
      this.commentDisabled = false;
      this.commentDialogVisible = false;
    },
    init() {
      edu.getCourseByList().then((res) => {
        if (res.code == 20000) {
          this.classList = res.data.items;
        }
      });
    },
    commentDialogClick(item) {
      this.dialogtitle = item.title + "(" + item.teacherName + ")";
      this.getCourseComment(item.id);
      this.commentDialogVisible = true;
    },
    commentClick() {
      edu.saveCourseComment(this.CommentForm).then((res) => {
        if (res.code == 20000) {
          this.$message.success(res.message);
          this.commentDialogVisible = false;
          this.CommentForm = { electiveId: null, content: "", number: 0 };
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getCourseComment(id) {
      edu.getCourseComment(id).then((res) => {
        if (res.code == 20000 && res.data.comment != null) {
          this.CommentForm = res.data.comment;
          this.commentDisabled = true;
        } else {
          this.CommentForm = { electiveId: id, content: "", number: 0 };
          this.commentDisabled = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-title-box {
  display: flex;
  height: 51px;
  line-height: 51px;
  border-bottom: 1px solid #e2e2e2;
  background: #ebeef5;

  .tab-item {
    margin-left: 40px;
    color: $theme-color-font;
    line-height: 51px;
    height: 52px;
    min-width: 65px;
    padding: 0 15px;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 2px solid $theme-color-font;
    background: #fff;
  }
}
.tab-content-box {
  background: #fff;
  padding: 0 30px;
  .personal-base-header {
    font-size: 14px;
    font-weight: 400;
    padding: 0 15px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .class-content {
    background: #fff;
    min-height: 559px;
    border-radius: 6px;
    .class-ctx {
      padding: 39px 0 39px 13px;
      .class-item {
        position: relative;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 20px;
        float: left;
        transition: all 0.1s;
        position: relative;
        cursor: pointer;
        width: 200px;
        padding: 0 0 10px;
        overflow: hidden;
        border-radius: 6px;
        box-shadow: rgba(215, 219, 221, 0.5) 8px 8px 18px 0;
        img {
          width: 100%;
          height: 134px;
          margin-bottom: 10px;
        }
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:hover {
          -webkit-box-shadow: 0 4px 4px 1px #e0ecfb;
          box-shadow: 0 4px 4px 1px #e0ecfb;
          -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
          background: #f5f7fa;
        }

        .outbtn {
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 1;
        }
        .outbtn svg {
          width: 25px;
          height: 25px;
          background: linear-gradient(to right, #ec4899, #ef4444, #eab308);
          border-color: transparent;
          border-radius: 50%;
        }
        .outbtn svg path:nth-child(1) {
          fill: #efeeee;
        }
        .outbtn svg:hover path:nth-child(1) {
          fill: #ffffff;
        }
      }
    }
  }
}
</style>
